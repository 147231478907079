import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import Wallet from  './pages/wallet';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Wallet />}>
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
