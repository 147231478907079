import React, { Loader, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Row, Col } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

const myFunction = () => {
  let x = document.getElementById('myLinks');

  if (x.style.display == 'blocks') {
    x.style.display = 'none';
  } else {
    x.style.display = 'blocks';
  }
};

const Test = () => {
  const [loader, setLoader] = useState(true);
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
      console.log('This will run after 1 second!');
    }, 2000);
  });
  return loader ? (
    <div className='loader-img'>
      <div className='inner-loader'>
        <img src='loader.gif' alt='' />
        {/* <img src='img/logo.png' alt='' style={{ height: '31px' }} /> */}
      </div>
    </div>
  ) : (
    <>
      <section id='section1-c' class='section-wrap'>
        <header class='py-4'>
          <div class='container'>
            <div class='row justify-content-around'>
              <div class='col-lg-6 col-md-6 col-sm-3'>
                <a href='#'>
                  <img src='img/logo.png' alt='' class='img-fluid logo' />
                </a>
              </div>
              <div class='col-lg-6 col-md-6 col-sm-9 nav-align '>
                <a href='#'>
                  <img src='img/contact/Layer 2.png' alt='' class='img-fluid d-sm-none1' />
                </a>
                <a href='#' class='btn mx-3 text-white btn-cw'>
                  CONNECT WALLET
                </a>
                <a href='javascript:void(0);' class='icon' onClick='myFunction()'>
                  <i class='fa fa-bars text-white'></i>
                </a>
              </div>
              <div class='topnav'>
                <div class='container'>
                  <div id='myLinks'>
                    <div class='container'>
                      <div class='row margin-left'>
                        <div class='col-6 mt-5 mb-3'>
                          <a href='#' class='link'>
                            Home
                          </a>
                          <a href='#' class='link'>
                            Morph ManiFesto
                          </a>
                          <a href='#' class='link'>
                            Morph Collection
                          </a>
                          <a href='#' class='link'>
                            Diamond Neck
                          </a>
                          <a href='#' class='link'>
                            Founders
                          </a>
                        </div>
                        <div class='col-6' id='social'>
                          <a href='javascript:void(0);' class='icon' onClick='myFunction()'>
                            <i class='fa fa-close'></i>
                          </a>
                          <p>Socials</p>
                          <a href='#' class='link2'>
                            Tweeter
                          </a>
                          <a href='#' class='link2'>
                            Instgram
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class='container my-5 mb-0'>
          <div class='row'>
            <div class='col-lg-6 col-md-6'>
              <div class='img-wrap'>
                <img src='img/contact/5.png' class='img-fluid' alt='' />
              </div>
            </div>
            <div class='col-lg-6 col-md-6'>
              <div class='box1-c'>
                <div class='d-flex'>
                  <img src='img/logo.png' alt='' style={{ height: '31px' }} />
                  <h2 class='mx-3'>MINT</h2>
                  <p class='align-self-end'>(2 Kings Max)</p>
                </div>
                &nbsp; &nbsp; &nbsp;
                <div class='d-flex'>
                  <img src='img/contact/Shape 3.png' class='align-self-start' />
                  <p class='mx-2'>Sale ends august 26, 2022 at 1:14pm GMT+5</p>
                </div>
                <div class='hr'></div>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <p>Current price:</p>
                <div class='d-flex'>
                  <img src='img/contact/Vector Smart Object.png' class='align-self-center' />
                  <h2 class='mx-3'>1 NFT=0.5 ETH</h2>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <div class='d-flex'>
                  <p>QTY:</p>
                  <div class='qty mx-3'>
                    <input
                      type='number'
                      name='qty'
                      id='quantity_wanted'
                      // value={2}
                      class='input-group'
                      min={1}
                      max={3}
                      // aria-label="{l s='Quantity' d='Shop.Theme.Actions'}"
                    />
                  </div>
                </div>
                <div>
                  <div class='buttons-wrap'>
                    <span
                      class='btn my-5 text-white btn'
                      onClick={() => {
                        setLoader(true);
                        setTimeout(() => {
                          setLoader(false);
                          console.log('This will run after 1 second!');
                        }, 2000);
                      }}
                    >
                      BUY NOW
                    </span>
                    <a href='' class='btn my-5 text-white btn' data-bs-toggle='modal' data-bs-target='#exampleModal1'>
                      <b>VIEW LOADER</b>
                    </a>
                  </div>

                  <div class='modal fade' id='exampleModal' tabindex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
                    <div class='modal-dialog'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                        </div>
                        <div class='modal-body'>
                          <h5 class='modal-title text-dark text-center fs-3' id='exampleModalLabel'>
                            You Can Check Out your item on Opensea.
                          </h5>

                          <img src='img/contact/Layer 1.png' class='img-fluid rounded mx-auto d-block' alt='' style={{ height: '200px' }} />
                        </div>
                        <div class='d-flex justify-content-center'>
                          <a href='#' class='btn text-white my-3'>
                            VIEW ITEMS
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Test;
